










































































































































































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataResponseListJoinInvoiceAndPrepayment,
  RequestPayloadJoinInvoice,
} from "@/models/interface/invoice.interface";
import { invoiceServices } from "@/services/invoice.service";
import moment, { Moment } from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";

@Component
export default class JoinInvoiceFrom extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  @Ref("form") form;
  joinInvoiceForm = {
    branch: "",
    joinInvoiceNo: "",
    customerName: "",
    createdDate: moment(),
    exchangeDate: null as Moment | null | string,
    dueDate: null as Moment | null | string,
    currency: "",
  };
  selectedRowKeys = [] as number[];
  loadingFind = false;
  loadingSubmit = false;
  loadingCancel = false;
  id = "";
  page = 1;
  limit = 10;
  totalData = 0;
  formRules = {
    branch: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    customerName: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    exchangeDate: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    dueDate: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    currency: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
  };
  title = "lbl_create_join_incoices";
  tableColumns = [
    {
      title: this.$t("lbl_ar_invoice_number").toString(),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 200,
    },
    {
      title: this.$t("lbl_invoice_date").toString(),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_due_date").toString(),
      dataIndex: "dueDate",
      key: "dueDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_invoice_total").toString(),
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
  ];
  invoicesData: DataResponseListJoinInvoiceAndPrepayment[] = [];
  grandTotal = 0;
  disableForm = false;

  created(): void {
    if (this.$route.params.id) {
      this.title = "lbl_view_join_incoices";
      this.disableForm = true;
      this.id = this.$route.params.id;
      this.getDetailInvoice();
    } else {
      this.title = "lbl_create_join_incoices";
      this.disableForm = false;
    }
  }

  onSelectChange(value) {
    this.selectedRowKeys = value;
  }

  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
    this.findInvoices();
  }

  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
    this.findInvoices();
  }

  assignSearch(key, value, and): string {
    if (key === "branch" && value)
      return and + `branchWarehouse.secureId~${value}`;
    else if (key === "customerName" && value)
      return and + `customer.secureId~${value}`;
    else if (key === "currency" && value)
      return and + `priceCurrency.currencyCode~${value}`;
    else if (key === "joinInvoiceNumber" && value)
      return and + `documentNumber~${value}`;
    else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  findInvoices() {
    this.form.validate(valid => {
      if (valid) {
        const params: RequestQueryParamsModel = {
          page: this.page - 1,
          limit: this.limit,
        };
        params.search = this.dynamicSearch(this.joinInvoiceForm);
        this.loadingFind = true;
        invoiceServices
          .getListJoinInvoiceAndPrepayment(params)
          .then(response => {
            this.totalData = response.totalElements;
            this.invoicesData = response.data.map((dataMap, index) => {
              return {
                ...dataMap,
                invoiceTotal: dataMap.invoiceTotal,
                key: index,
              };
            });
            this.grandTotal = response.data.reduce((a, b) => {
              return a + b.invoiceTotal;
            }, 0);
          })
          .finally(() => (this.loadingFind = false));
      }
    });
  }

  handleBack() {
    this.$confirm({
      title: this.$t("lbl_leave_page"),
      onOk: () => {
        this.$router.push("/accountreceivables/join-invoices");
      },
      onCancel() {
        return;
      },
    });
  }

  handleCancel() {
    this.$confirm({
      title: "Are you sure want to cancel?",
      onOk: () => {
        this.loadingCancel = true;
        invoiceServices
          .cancelJoinInvoice(this.id)
          .then(() =>
            this.$notification.success({
              message: "Success",
              description: `Success Cancel`,
            })
          )
          .finally(() => (this.loadingCancel = false));
      },
      onCancel() {
        return;
      },
    });
  }

  handleSubmit() {
    this.form.validate(valid => {
      if (valid) {
        if (this.selectedRowKeys.length > 0) {
          const payload: RequestPayloadJoinInvoice = {
            branchWarehouseId: this.joinInvoiceForm.branch,
            currency: this.joinInvoiceForm.currency,
            customerId: this.joinInvoiceForm.customerName,
            date: moment(this.joinInvoiceForm.createdDate).format(),
            dueDate: moment(this.joinInvoiceForm.dueDate).format(),
            exchangeDate: moment(this.joinInvoiceForm.exchangeDate).format(),
            invoiceJoinLines: this.invoicesData
              .filter((_dataFilter, index) =>
                this.selectedRowKeys.includes(index)
              )
              .map(dataMap => {
                return {
                  ...dataMap,
                  invoiceARId: dataMap.invoiceId,
                  totalInvoice: dataMap.invoiceTotal,
                };
              }),
          };
          this.loadingSubmit = true;
          invoiceServices
            .createJoinInvoice(payload)
            .then(response => {
              this.$notification.success({
                message: "Success",
                description: `documentNumber: ${response.documentNumber}`,
                duration: 30,
              });
              this.$router.push("/accountreceivables/join-invoices");
            })
            .finally(() => (this.loadingSubmit = false));
        } else {
          this.$notification.error({
            message: "error",
            description: "Select data first to submit",
          });
        }
      }
    });
  }

  getDetailInvoice() {
    invoiceServices.getDetailJoinInvoiceNumber(this.id).then(response => {
      this.joinInvoiceForm.branch = response.branchWarehouseId;
      this.joinInvoiceForm.joinInvoiceNo = response.documentNumber;
      this.joinInvoiceForm.customerName = response.customerId;
      this.joinInvoiceForm.createdDate = moment(response.createdDate);
      this.joinInvoiceForm.exchangeDate = moment(response.exchangeDate);
      this.joinInvoiceForm.dueDate = moment(response.dueDate);
      this.joinInvoiceForm.currency = response.currency;

      this.invoicesData = response.invoiceJoinLines.map(dataMap => {
        return {
          invoiceNumber: dataMap.invoiceNumber,
          invoiceId:
            (dataMap.invoiceARId as string) ||
            (dataMap.invoicePrepaymentId as string),
          invoicePrepaymentId: dataMap.invoicePrepaymentId as string,
          invoiceTotal: dataMap.totalInvoice,
          totalInvoice: dataMap.totalInvoice,
          invoiceDate: dataMap.invoiceDate,
          dueDate: dataMap.dueDate,
          id: dataMap.id,
          invoiceARId: dataMap.invoiceARId as string,
        };
      });
      this.totalData = this.invoicesData.length;
      this.grandTotal = this.invoicesData.reduce((a, b) => {
        return a + b.invoiceTotal;
      }, 0);
    });
  }
}
